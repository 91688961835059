import React from "react";
import "./About.css";
import { aboutLineData } from "../data/aboutData";

function About({ reference }) {
  return (
    <section id="about" className="about" ref={reference}>
      <div className="container">
        <div className="row content">
          <div className="col-lg-6">
            <h2>布里斯班澳信教育</h2>
            <h3>
              澳信教育集团 -
              布里斯班公司，为赴澳留学的同学们竭诚服务。让无微不至的优质服务，成就你的澳洲留学、发展计划。
              选择澳信，选择未来
            </h3>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>
              资深的留学顾问团队，精英的培训师团队，专业的移民咨询团队，共同打造着我们不断追求卓越的企业文化，
              以及点滴铸非凡的做事态度，我们承诺对每一个客户及学生负责
            </p>
            <ul>
              {aboutLineData.map((item) => (
                <li key={item.id}>
                  <i className={item.icon}></i>
                  {item.content}
                </li>
              ))}
            </ul>
            <p className="fst-italic">
              选择澳信教育，让你感受不一样的氛围，体验高端、优质的服务流程和结果
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
