import usImg from "../assets/portfolio/us-bg-2.jpg";
import uqImg from "../assets/portfolio/uq-bg-2.jpg";
import umImg from "../assets/portfolio/um-bg.jpg";
import stockOne from "../assets/portfolio/stock-bg-2.jpg";
import stockTwo from "../assets/portfolio/stock-bg-3.jpg";
import stockThree from "../assets/portfolio/stock-bg-4.jpg";
import managerOne from "../assets/portfolio/aoxin-meeting.jpg";
import managerTwo from "../assets/portfolio/aoxin-party.jpg";
import managerThree from "../assets/portfolio/aoxin-travel.jpg";
import dataOne from "../assets/portfolio/data-bg-1.jpg";
import dataTwo from "../assets/portfolio/data-bg-2.jpg";
import dataThree from "../assets/portfolio/data-bg-3.jpg";
import internOne from "../assets/portfolio/intership-bg-1.jpg";
import internTwo from "../assets/portfolio/intership-bg-2.jpg";
import internThree from "../assets/portfolio/intership-bg-3.jpg";
import tutoringOne from "../assets/portfolio/tutoring-bg-1.jpg";
import tutoringTwo from "../assets/portfolio/tutoring-bg-2.jpg";
import tutoringThree from "../assets/portfolio/tutoring-bg-3.jpg";

const projectDetailsData = [
  {
    pageTitle: "澳信(BNE)活动项目",
  },
  {
    id: 1,
    projectImg: [
      {
        id: 1,
        imgSrc: uqImg,
        imgAlt: "University of Queensland",
      },
      {
        id: 2,
        imgSrc: usImg,
        imgAlt: "University of Sydney",
      },
      {
        id: 3,
        imgSrc: umImg,
        imgAlt: "University of Melbourne",
      },
    ],
    category: "留学院校申请",
    location: "布里斯班",
    date: "2014年至今",
    link: "",
    title: "名校申请 定制咨询",
    description: `澳信教育拥有资深教育顾问，十年磨一剑，各类签证申请无一例失败。各院校申请经验丰富，擅长处理疑难案例。以最认真的态度，过硬的经验，真诚的意见帮助过大量学，为同学们的留学之路护航。`,
  },
  {
    id: 2,
    projectImg: [
      {
        id: 1,
        imgSrc: stockOne,
        imgAlt: "Stock Analyst Image",
      },
      {
        id: 2,
        imgSrc: stockTwo,
        imgAlt: "Stock Analyst Image",
      },
      {
        id: 3,
        imgSrc: stockThree,
        imgAlt: "Stock Analyst Image",
      },
    ],
    category: "教育培训",
    location: "布里斯班",
    date: "2020年至今",
    link: "",
    title: "注册金融分析师培训 (CFA)",
    description: `特许金融分析师（Chartered Financial Analyst，简称CFA）代表全球投资行业最高水平并受到最高道德标准约束。
    CFA是由美国投资管理与研究协会（AIMR）于1963年开始设立的特许金融分析师职业资格认证。其职业考试每年举办两次，是世界上规模最大的职业考试之一，是当今世界证券投资与管理界普遍认可的一种职业称号。`,
  },
  {
    id: 3,
    projectImg: [
      {
        id: 1,
        imgSrc: managerOne,
        imgAlt: "Aoxin Meeting",
      },
      {
        id: 2,
        imgSrc: managerTwo,
        imgAlt: "Aoxin Party",
      },
      {
        id: 3,
        imgSrc: managerThree,
        imgAlt: "Aoxin Group Travel",
      },
    ],
    category: "教育留学",
    location: "布里斯班",
    date: "2014年至今",
    link: "",
    title: "澳信留学管家服务",
    description: `澳信教育重细节，抓全面，从学生们的细微处做起。入学，选课，辅导，转学，升学，技能培训，移民知识培训，移民英语培训，移民申请，各类签证办理。真正做到一站式豪华服务，学生在得助享受的不仅是优质的服务，还能收获无忧的留学旅程。`,
  },
  {
    id: 4,
    projectImg: [
      {
        id: 1,
        imgSrc: dataOne,
        imgAlt: "Data Analysis",
      },
      {
        id: 2,
        imgSrc: dataTwo,
        imgAlt: "Data Analysis",
      },
      {
        id: 3,
        imgSrc: dataThree,
        imgAlt: "Data Analysis",
      },
    ],
    category: "技能培训",
    location: "布里斯班",
    date: "2019年至今",
    link: "",
    title: "数据分析 (DA/BA) 技能培训",
    description: `数据分析是未来引领行业和社会发展的一个灵魂性行业。介于Python 在数据处理方面有
    独特的拓展，该语言在数据科学领域具有不可或缺的地位。BA Python培训课程致力于启蒙学员的编程思维，传授数据分析的核心方法和技术。
    让你对编程和数据处理达到初窥门径的水平，为之后进行深度数据分析做好铺垫。`,
  },
  {
    id: 5,
    projectImg: [
      {
        id: 1,
        imgSrc: internOne,
        imgAlt: "Internship",
      },
      {
        id: 2,
        imgSrc: internTwo,
        imgAlt: "Internship",
      },
      {
        id: 3,
        imgSrc: internThree,
        imgAlt: "Internship",
      },
    ],
    category: "团建实习",
    location: "布里斯班",
    date: "2021年至今",
    link: "",
    title: "澳信实习生管培计划",
    description: `澳信教育为优秀的学术及市场人才提供了一展抱负的平台。加入澳信(BNE)市场管培生计划，在这里人才一定会脱颖而出，用你们的能力和智慧去启迪未来的达人。改变人生从教育做起。欢迎优秀学术及市场人才，聚贤澳信，共创未来。`,
  },
  {
    id: 6,
    projectImg: [
      {
        id: 1,
        imgSrc: tutoringOne,
        imgAlt: "Tutoring Session",
      },
      {
        id: 2,
        imgSrc: tutoringTwo,
        imgAlt: "Tutoring Session",
      },
      {
        id: 3,
        imgSrc: tutoringThree,
        imgAlt: "Tutoring Session",
      },
    ],
    category: "辅导培训",
    location: "布里斯班",
    date: "2016年至今",
    link: "",
    title: "大学课程辅导培训",
    description: `资深大学讲师，各院校工商科达人，协力打造布里斯班最专业的课程培训机构，为同学们的追梦之路保驾护航。10年研究各学科及行业素材，为同学打造一套独家精品培训方案，无论您有什么样的学术和职业背景，我们有针对性教学方针。360°团队服务，保证给你和普通家教或私人辅导不一样的体验！`,
  },
];

export default projectDetailsData;
