import React, { useState } from "react";
import emailjs from "emailjs-com";

function ContactForm() {
  const intialState = {
    fullname: "",
    email: "",
    subject: "",
    message: "",
    result: "",
  };
  const [text, setText] = useState(intialState);

  const changeText = (e) => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value, result: "" });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (text.fullname === "" || text.email === "" || text.message === "") {
      setText({ ...text, result: "incomplete" });
      return;
    }
    // show pending info while sending email
    setText({ ...text, result: "loading" });
    emailjs
      .sendForm(
        "service_xrgm926",
        "template_a26oyux",
        e.target,
        "oBj6m8GRn5lpiTuYD"
      )
      .then(
        (result) => {
          //console.log(result.text);
          setText({
            ...intialState,
            result: "success",
          });
        },
        (error) => {
          //console.log(error.text);
          setText({
            ...intialState,
            result: "error",
          });
        }
      );
  };
  return (
    <form onSubmit={sendEmail} className="php-email-form">
      <div className="row">
        <div className="form-group col-md-6">
          <input
            type="text"
            name="fullname"
            value={text.fullname}
            onChange={changeText}
            className="form-control"
            id="name"
            placeholder="您的姓名"
          />
        </div>
        <div className="form-group col-md-6">
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            value={text.email}
            onChange={changeText}
            placeholder="您的邮箱"
          />
        </div>
      </div>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          name="subject"
          value={text.subject}
          onChange={changeText}
          id="subject"
          placeholder="您的主题"
        />
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          name="message"
          value={text.message}
          onChange={changeText}
          rows="5"
          placeholder="您的留言..."
        ></textarea>
      </div>
      <div className="my-3">
        {text.result === "loading" && <div className="loading">发送中</div>}
        {text.result === "error" && (
          <div className="error-message">抱歉，系统错误，信息发送失败</div>
        )}
        {text.result === "incomplete" && (
          <div className="error-message">请您填写以上全部信息，谢谢</div>
        )}
        {text.result === "success" && (
          <div className="sent-message">谢谢，您的信息已发送成功</div>
        )}
      </div>
      <div className="text-center">
        <button type="submit">发送信息</button>
      </div>
    </form>
  );
}

export default ContactForm;
