import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Footer.css";
import footerData from "../data/footerData";

function Footer({ refs }) {
  const location = useLocation();
  const handlePageScroll = (section) => (e) => {
    e.preventDefault();
    window.scrollTo(
      0,
      refs[section].current.offsetTop - refs.header.current.offsetHeight + 1
    );
  };
  return (
    <footer id="footer" ref={refs.footer}>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-info">
                <h3>AUSTLINK</h3>
                <p>{footerData.intro}</p>
                <div className="social-links mt-3">
                  {footerData.social.map((item) => (
                    <a key={item.id} href={item.link} className={item.name}>
                      <i className={item.icon}></i>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>{footerData.partTwoTitle}</h4>
              <ul>
                {footerData.links.map((item) => (
                  <li key={item.id}>
                    <i className="bx bx-chevron-right"></i>{" "}
                    {location.pathname === "/" ? (
                      <a onClick={handlePageScroll(item.link)}>{item.name}</a>
                    ) : (
                      <Link to={`/`}>{item.name}</Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>{footerData.portThreeTitle}</h4>
              <ul>
                {footerData.services.map((item) => (
                  <li key={item.id}>
                    <i className="bx bx-chevron-right"></i>{" "}
                    {location.pathname === "/" ? (
                      <a onClick={handlePageScroll("services")}>{item.name}</a>
                    ) : (
                      <Link to={`/`}>{item.name}</Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-newsletter">
              <h4>{footerData.portFourTitle}</h4>
              <p>{footerData.callUp}</p>
              <div className="d-flex align-items-center justify-content-center mt-3">
                <img
                  className="wechat"
                  src={footerData.imgUrl}
                  alt={footerData.imgAlt}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright {new Date().getFullYear()}{" "}
          <strong>
            <span>Austlink Brisbane</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Designed by{" "}
          <a target="_blank" href="https://www.dstudio.top-oz.com/">
            David Fan
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
