const counterData = [
  {
    id: 1,
    end: 1232,
    duration: 2,
    name: "服务客人数量",
  },
  {
    id: 2,
    end: 3216,
    duration: 2,
    name: "业务办理数量",
  },
  {
    id: 3,
    end: 2463,
    duration: 2,
    name: "技能培训小时",
  },
  {
    id: 4,
    end: 31,
    duration: 2,
    name: "团队成员数量",
  },
];

export default counterData;
