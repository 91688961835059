import firstMember from "../assets/team/team-1.jpg";
import secondMember from "../assets/team/team-2.jpg";
import thirdMember from "../assets/team/team-3.jpg";
import fourthMember from "../assets/team/team-4.jpg";

const teamData = [
  {
    id: 1,
    name: "David Fan",
    position: "Director",
    description:
      "澳信教育布里斯班办公室董事，多年热衷于教育事业，希望为更多的同学分享学习，及职场发展经验",
    imgSrc: firstMember,
  },
  {
    id: 2,
    name: "Lynn Wang",
    position: "Senior Education Consultant",
    description:
      "澳信教育布里斯班办公室资深教育顾问，10年+行业经验，把关顾问团队，捕捉行业动态，为同学们引航",
    imgSrc: secondMember,
  },
  {
    id: 3,
    name: "Xiaolin Wei",
    position: "Senior Education Consultant",
    description:
      "澳信教育布里斯班办公室资深教育顾问，各院校申请经验丰富，擅长处理疑难案例，帮助过大量学生",
    imgSrc: thirdMember,
  },
  {
    id: 4,
    name: "Anna Ding",
    position: "Senior Education Consultant",
    description:
      "澳信教育布里斯班办公室资深教育顾问，工作态度严谨认真，各类签证申请无一例失败，呵护每一个案例",
    imgSrc: fourthMember,
  },
];

export default teamData;
