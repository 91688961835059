import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import Breadcrumbs from "./Breadcrumbs";
import "./PortfolioDetails.css";
import projectDetailsData from "../data/projectDetailsData";
import NotFound from "../pages/NotFound";

SwiperCore.use([Autoplay, Pagination]);

function PortfolioDetails() {
  const { id: projectId } = useParams();
  const projectNumber = ["1", "2", "3", "4", "5", "6"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return projectNumber.includes(projectId) ? (
    <>
      <Helmet>
        <title>
          澳信教育 | 布里斯班 | {projectDetailsData[projectId].title}
        </title>
        <meta
          name="description"
          content={projectDetailsData[projectId].description}
        />
        <meta
          name="keywords"
          content="Australia,Queensland,Brisbane,澳大利亚,昆士兰,布里斯班,我附近的留学中介,我附近的大学辅导,商科辅导,IT辅导,IS辅导,Business Information,教育,中介,留学,留学中介,留学顾问,移民,辅导,培训,教育机构,大学,课程,大学辅导,课程辅导,补习,技能培训,职业培训,数据分析培训,Data Analyst,IT 培训,实习,找工作,工作实习,Education Agent near me,昆士兰大学,昆士兰科技大学,格里菲斯大学,附近的留学中介,市中心,Brisbane City,Queen Street,Adelaide Street,Edward Street"
        />
      </Helmet>
      <Breadcrumbs title={projectDetailsData[0].pageTitle} page="活动信息" />
      <section id="portfolio-details" className="portfolio-details">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-8">
              <div className="portfolio-details-slider swiper-container">
                <Swiper
                  speed={600}
                  loop={true}
                  autoplay={{ delay: 5000, disableOnInteraction: false }}
                  slidesPerView={"auto"}
                  pagination={{
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true,
                  }}
                >
                  {projectDetailsData[projectId].projectImg.map((item) => (
                    <SwiperSlide key={item.id} className="swiper-slide">
                      <img src={item.imgSrc} alt={item.imgAlt} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="swiper-pagination"></div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="portfolio-info">
                <h3>活动信息</h3>
                <ul>
                  <li>
                    <strong>类别</strong>:{" "}
                    {projectDetailsData[projectId].category}
                  </li>
                  <li>
                    <strong>位置</strong>:{" "}
                    {projectDetailsData[projectId].location}
                  </li>
                  <li>
                    <strong>活动时期</strong>:{" "}
                    {projectDetailsData[projectId].date}
                  </li>
                  <li>
                    <strong>活动链接</strong>:{" "}
                    <Link to="/">{projectDetailsData[projectId].link}</Link>
                  </li>
                </ul>
              </div>
              <div className="portfolio-description">
                <h2>{projectDetailsData[projectId].title}</h2>
                <p>{projectDetailsData[projectId].description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    <NotFound />
  );
}

export default PortfolioDetails;
