import portfolioImg1 from "../assets/portfolio/uq-bg.jpg";
import portfolioImg3 from "../assets/portfolio/stock-bg.jpg";
import portfolioImg4 from "../assets/portfolio/us-bg.jpg";
import portfolioImg5 from "../assets/portfolio/data-bg.jpg";
import portfolioImg7 from "../assets/portfolio/intership-bg.jpg";
import portfolioImg9 from "../assets/portfolio/tutoring-bg.jpg";

const portfolioHeadData = {
  title: "项目分享",
  content: "",
};

const filterData = [
  {
    id: 1,
    filter: "*",
    name: "全部",
    active: true,
  },
  {
    id: 2,
    filter: "filter-educ",
    name: "留学",
    active: false,
  },
  {
    id: 3,
    filter: "filter-activity",
    name: "团建",
    active: false,
  },
  {
    id: 4,
    filter: "filter-training",
    name: "培训",
    active: false,
  },
];

const portfolioData = [
  {
    id: 1,
    filter: "filter-educ",
    imgUrl: portfolioImg1,
    imgAlt: "University of Queensland",
    title: "开启澳洲知名大学的成功申请",
    content: "成功办理澳洲八大及其它各知名院校申请，实现你的名校梦",
  },
  {
    id: 2,
    filter: "filter-training",
    imgUrl: portfolioImg3,
    imgAlt: "CFA Training",
    title: "注册金融分析师培训 (CFA)",
    content: "成功开设多期 CFA 培训课程，金融分析师考试无忧",
  },
  {
    id: 3,
    filter: "filter-educ",
    imgUrl: portfolioImg4,
    imgAlt: "University of Sydney",
    title: "澳信学员享受一站式留学管家服务",
    content: "呵护每一位学员从入学，转学，升学，到选课，学术纠纷申诉",
  },
  {
    id: 4,
    filter: "filter-training",
    imgUrl: portfolioImg5,
    imgAlt: "Data Analytics Training",
    title: "数据分析 (DA/BA) 技能培训 ",
    content: "Excel/Python/SQL 数据培训课程，BA 速成之路",
  },
  {
    id: 5,
    filter: "filter-activity",
    imgUrl: portfolioImg7,
    imgAlt: "Hot Port Function",
    title: "澳信实习生管培计划",
    content: "加入澳信，参与市场实践活动，获得宝贵实习经验",
  },

  {
    id: 6,
    filter: "filter-training",
    imgUrl: portfolioImg9,
    imgAlt: "University Tutoring",
    title: "大学课程辅导培训",
    content: "本科、研究生 - 商科、信息系统、数据分析培训",
  },
];

export { portfolioData, filterData, portfolioHeadData };
