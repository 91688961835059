const contactData = {
  content: `欢迎咨询澳信教育，请将你有关留学，移民和技能培训方面的问题告诉我们，我们会在第一时间竭诚为你服务`,
  mapUrl: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d226562.93069468962!2d152.9870453882651!3d-27.467833714642804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b915b570847091f%3A0xb551d46e4cfbaf1d!2z5r6z5L-h5pWZ6IKyIEF1c3RsaW5rIEVkdWNhdGlvbiAoQk5FKQ!5e0!3m2!1sen!2sau!4v1676938756710!5m2!1sen!2sau`,
  address: `S4 Level 7, 243 Edward Street`,
  addressSecondLine: "Brisbane QLD 4000",
  tel: "+61 0404 368 885",
  phone: "+61 0405 817 909",
  email: "brisbane@austlinkvisa.com",
  secondEmail: "infobrisbane@austlinkvisa.com",
};

export default contactData;
