import firstSlide from "../assets/slide/slide-4.jpg";
import secondSlide from "../assets/slide/slide-2.jpg";
import thirdSlide from "../assets/slide/slide-5.jpg";

const heroIndicator = [
  {
    id: 1,
    status: "active",
  },
  {
    id: 2,
    status: "",
  },
  {
    id: 3,
    status: "",
  },
];

const heroData = [
  {
    id: 1,
    status: "active",
    imgSrc: firstSlide,
    title: "专业留学顾问",
    content: `澳信教育提供资深教育顾问，十年磨一剑，澳洲各类签证申请无一例失败。全澳各院校申请经验丰富，
      擅长处理疑难案例。以最认真的态度，过硬的经验，真诚的意见帮助过大量留学生，
      为同学们的留学之路护航`,
    btnText: "了解更多",
  },
  {
    id: 2,
    status: "",
    imgSrc: secondSlide,
    title: "资深移民代理",
    content: `澳信教育打造权威移民代理团队，以高超的专业水平，锲而不舍的精神，为申请人争取最大利益的态度，
      为申请人获取了澳大利亚各类技术移民签证，工作签证，各类投资移民签证和各类其他永居签证`,
    btnText: "了解更多",
  },
  {
    id: 3,
    status: "",
    imgSrc: thirdSlide,
    title: "顶尖技能培训",
    content: `澳信教育开设精英技能培训课程，致力于让同学们在轻松的环境下学习实用行业技能。
      培训课程包含: IT编程 (Programming)，应用开发 (App Development)，数据分析 (Data Analytics)，
      财会 (Accounting)，金融投资 (Finance & Investment) 等热门领域`,
    btnText: "了解更多",
  },
];

export { heroIndicator, heroData };
