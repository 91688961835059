import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Isotope from "isotope-layout";
import Glightbox from "glightbox";
import "./Portfolio.css";
import { portfolioData, filterData } from "../data/portfolioData";

function Portfolio({ reference }) {
  const isotope = useRef();
  const [filterKey, setFilterKey] = useState("*");
  const [filter, setFilter] = useState(filterData);

  new Glightbox({
    selector: ".portfolio-lightbox",
  });

  useEffect(() => {
    isotope.current = new Isotope(".portfolio-container", {
      itemSelector: ".portfolio-item",
      layoutMode: "fitRows",
    });
    // cleanup
    return () => isotope.current.remove();
  }, []);

  useEffect(() => {
    filterKey === "*"
      ? isotope.current.arrange({ filter: "*" })
      : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  useEffect(() => {
    setTimeout(function () {
      isotope && isotope.current.arrange({ filter: "*" });
    }, 1500);
  }, []);

  // change the active filter
  const handleFilterKeyChange = (key, id) => {
    setFilterKey(key);
    const newFilter = filter.map((item) => {
      item.active = false;
      if (item.id === id) item.active = true;
      return item;
    });
    setFilter(newFilter);
  };

  return (
    <section id="portfolio" className="portfolio" ref={reference}>
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <span>Portfolio</span>
          <h2>活动展示</h2>
          <p>
            当您全面了解了澳信的实力和服务质量之后，您会更加清晰选择我们的理由，在追求卓越的过程中，
            请允许我们参与进来，让您的留学旅程更加完美
          </p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-12">
            <ul id="portfolio-flters">
              {filter.map((item) => (
                <li
                  key={item.id}
                  data-filter={item.filter}
                  className={item.active ? "filter-active" : undefined}
                  onClick={() => handleFilterKeyChange(item.filter, item.id)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {portfolioData.map((item) => (
            <div
              key={item.id}
              className={`col-lg-4 col-md-6 portfolio-item ${item.filter}`}
            >
              <div className="portfolio-wrap">
                <img
                  src={item.imgUrl}
                  className="img-fluid"
                  alt={item.imgAlt}
                />
                <div className="portfolio-info">
                  <h4>{item.title}</h4>
                  <p>{item.content}</p>
                  <div className="portfolio-links">
                    <a
                      href={item.imgUrl}
                      data-lightbox="portfolio"
                      data-title={item.title}
                      className="portfolio-lightbox"
                      data-gallery="portfolioGallery"
                    >
                      <i className="ri-add-fill"></i>
                    </a>
                    <Link
                      to={`/project-details/${item.id}`}
                      title="More Details"
                    >
                      <i className="ri-links-fill"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
