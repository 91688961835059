import wechatQR from "../assets/austlink.jpeg";

const footerData = {
  intro: `澳信教育是澳大利亚专业留学移民咨询中心，精英技能培训机构，
  拥有20年以上的行业资历。专业且庞大的咨询顾问和技能培训师团队，为同学们的澳洲梦开创不一样的前景。
  选择澳信进入你留学的理想港湾，圆梦青春不再是一句空谈`,
  partTwoTitle: "热点链接",
  links: [
    {
      id: 1,
      name: "主页 Home",
      link: "header",
    },
    {
      id: 2,
      name: "关于我们 About Us",
      link: "about",
    },
    {
      id: 3,
      name: "活动展示 Portfolio",
      link: "portfolio",
    },
    {
      id: 4,
      name: "团队介绍 Our Team",
      link: "team",
    },
    {
      id: 5,
      name: "联系我们 Contact",
      link: "contact",
    },
  ],
  portThreeTitle: "核心服务",
  services: [
    {
      id: 1,
      name: "留学咨询 院校申请",
      link: "",
    },
    {
      id: 2,
      name: "选课指导 学习规划",
      link: "",
    },
    {
      id: 3,
      name: "境内外签证办理",
      link: "",
    },
    {
      id: 4,
      name: "大学及职业技能培训",
      link: "",
    },
    {
      id: 5,
      name: "移民咨询及代理",
      link: "",
    },
  ],
  social: [
    {
      id: 1,
      name: "facebook",
      icon: "bx bxl-facebook",
      link: "",
    },
    {
      id: 2,
      name: "linkedin",
      icon: "bx bxl-linkedin",
      link: "",
    },
    {
      id: 3,
      name: "youtube",
      icon: "bx bxl-youtube",
      link: "",
    },
    {
      id: 4,
      name: "twitter",
      icon: "bx bxl-twitter",
      link: "",
    },
    {
      id: 5,
      name: "instagram",
      icon: "bx bxl-instagram",
      link: "",
    },
  ],
  portFourTitle: "添加微信",
  callUp: `扫描下方二维码添加微信，我们的的客服人员会为您提供在线咨询服务，包含澳洲大学选课，
  院校咨询，留学，移民方案及其他技能培训计划`,
  imgUrl: wechatQR,
  imgAlt: "WeChat",
};

export default footerData;
