import React, { useState, useEffect } from "react";
import "./Team.css";
import teamData from "../data/teamData";

function Team({ reference }) {
  const [team, setTeam] = useState([]);
  useEffect(() => {
    setTeam(teamData);
  }, []);
  return (
    <section id="team" className="team" ref={reference}>
      <div className="container">
        <div className="section-title">
          <span>Team</span>
          <h2>核心团队</h2>
          <p>
            资深大学教培团队，教育顾问，协力打造布里斯班最专业的教育咨询和技能培训机构，为同学们的追梦之路保驾护航
          </p>
        </div>

        <div className="row">
          {team.map((item) => (
            <div key={item.id} className="col-lg-6 mt-4">
              <div className="member d-flex align-items-start">
                <div className="pic">
                  <img src={item.imgSrc} className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>{item.name}</h4>
                  <span>{item.position}</span>
                  <p>{item.description}</p>
                  <div className="social">
                    <a href="">
                      <i className="ri-twitter-fill"></i>
                    </a>
                    <a href="">
                      <i className="ri-facebook-fill"></i>
                    </a>
                    <a href="">
                      <i className="ri-instagram-fill"></i>
                    </a>
                    <a href="">
                      {" "}
                      <i className="ri-linkedin-box-fill"></i>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Team;
