import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import counterData from "../data/counterData";
import "./Counter.css";

function Counter({ scroll, refs }) {
  const [counterActive, setCounterActive] = useState(false);

  useEffect(() => {
    setCounterActive(
      scroll > 0 &&
        scroll >=
          refs.counter.current.offsetTop - refs.header.current.offsetHeight
    );
  }, [scroll]);

  return (
    <section id="counts" className="counts" ref={refs.counter}>
      <div className="container position-relative">
        <div className="text-center title">
          <h3>我们的成绩</h3>
          <p>
            用真诚服务客人，以优质回报学生，澳信教育拥有业内良好的口碑，
            是大量学生的首选机构
          </p>
        </div>

        <div className="row counters">
          {counterData.map((item) => (
            <div key={item.id} className="col-lg-3 col-6 text-center">
              <span>
                {counterActive ? (
                  <CountUp end={item.end} duration={item.duration} />
                ) : (
                  item.end
                )}
              </span>
              <p>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Counter;
