import avatar from "../assets/testimonials/avatar.jpg";

const testimonialsData = [
  {
    id: 1,
    name: "毕业研究生",
    occupation: "昆士兰大学",
    imgUrl: avatar,
    imgAlt: "UQ Student",
    comments: `本科成绩不是很理想，幸好遇到了澳信，帮我在申请研究生的时候和大学极力争取，
    终于让我进入了UQ，也算是圆了自己的名校情结。不仅如此，澳信还帮我最大化的申请了折抵学分，
    让我可以读一年半就研究生毕业，真的超赞！`,
  },
  {
    id: 2,
    name: "在读研究生",
    occupation: "悉尼大学",
    imgUrl: avatar,
    imgAlt: "USYD Student",
    comments: `悉大的数据分析 (DA) 专业，真的很刚，学的挺深的，但是非常有助于就业。
    幸亏提前报名了澳信的数据分析培训班，让我在研究生开学前就提前学会了Python 和 SQL。
    开学后感觉自己在学习中就像是开挂了一样，都听得懂，所以真心推荐大家来参加技能培训。`,
  },
  {
    id: 3,
    name: "毕业本科学生",
    occupation: "昆士兰科技大学",
    imgUrl: avatar,
    imgAlt: "QUT Student",
    comments: `澳信的管家服务真的特别细腻，我由于毕业回国，但是澳洲房子提前到期，澳信就帮我联系到了短租，
    之后回国要寄毕业证，怕寄丢了，澳信就让大学把毕业证先临时寄到他们公司，让后他们再用更好的国际快递寄给大家，
    很多类似的贴心服务，澳信都在默默的为同学们做着。这里，我是真心推荐这个靠谱的机构给大家。`,
  },
];

export default testimonialsData;
