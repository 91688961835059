import React from "react";
import "./TopBar.css";

function TopBar({ scroll }) {
  return (
    <section
      id="topbar"
      className={`fixed-top d-flex align-items-center ${
        scroll > 100 ? "topbar-scrolled" : undefined
      }`}
    >
      <div className="container d-flex justify-content-center justify-content-md-between">
        <div className="contact-info d-flex align-items-center">
          <i className="bi bi-envelope d-flex align-items-center">
            <a href="mailto:brisbane@austlinkvisa.com">
              brisbane@austlinkvisa.com
            </a>
          </i>
          <i className="bi bi-phone d-flex align-items-center ms-4">
            <span>+610405817909</span>
          </i>
        </div>
        <div className="social-links d-none d-md-flex">
          <a href="#" className="facebook">
            <i className="bi bi-facebook"></i>
          </a>
          <a href="#" className="twitter">
            <i className="bi bi-twitter"></i>
          </a>
          <a href="#" className="youtube">
            <i className="bi bi-youtube"></i>
          </a>
          <a href="#" className="linkedin">
            <i className="bi bi-linkedin"></i>
          </a>
        </div>
      </div>
    </section>
  );
}

export default TopBar;
