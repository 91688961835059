import React, { useState, useEffect } from "react";
import "./Services.css";
import servicesData from "../data/servicesData";

function Services({ reference }) {
  const [services, setServices] = useState([]);

  useEffect(() => {
    setServices(servicesData);
  }, []);

  return (
    <section id="services" className="services section-bg" ref={reference}>
      <div className="container">
        <div className="section-title">
          <span>Services</span>
          <h2>服务介绍</h2>
          <p>
            澳信教育重细节，抓全面，从学生们的细微处做起。申学，选专业，选课，转学，升学，
            移民知识讲座，职业技能培训，移民申请，各类签证办理。真正做到一站式服务，
            学生在澳信享受的不仅是优质的服务，还能收获无忧的留学旅程
          </p>
        </div>

        <div className="row">
          {services.map((item) => (
            <div
              key={item.id}
              className={item.id === 1 ? "col-md-6" : "col-md-6 mt-4 mt-md-0"}
            >
              <div className="icon-box">
                <i className={item.icon}></i>
                <h4>
                  <a href="#">{item.title}</a>
                </h4>
                <p>{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
