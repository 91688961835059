import React from "react";
import "./Header.css";
import logo from "../assets/Austlink-logo.png";
import Nav from "./Nav";
import TopBar from "./TopBar";

function Header({ scroll, refs }) {
  return (
    <>
      <TopBar scroll={scroll} />
      <header
        id="header"
        ref={refs.header}
        className={`fixed-top d-flex align-items-center ${
          scroll > 100 ? "header-scrolled" : undefined
        }`}
      >
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="" className="img-fluid" />
            </a>
          </div>
          <Nav scroll={scroll} refs={refs} />
        </div>
      </header>
    </>
  );
}

export default Header;
