import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../components/Hero";
import Main from "../components/Main";

function Home({ scroll, refs }) {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          澳信教育 | 布里斯班顶尖留学,移民,大学辅导,技能培训,职业培训,教育机构
        </title>
        <meta
          name="description"
          content="澳大利亚,Australia,布里斯班,Brisbane,顶尖留学,移民,培训,大学辅导,课程补习,技能培训,职业培训,工作实习,教育机构"
        />
        <meta
          name="keywords"
          content="Australia,Queensland,Brisbane,澳大利亚,昆士兰,布里斯班,我附近的留学中介,我附近的大学辅导,商科辅导,IT辅导,IS辅导,Business Information,教育,中介,留学,留学中介,留学顾问,移民,辅导,培训,教育机构,大学,课程,大学辅导,课程辅导,补习,技能培训,职业培训,数据分析培训,Data Analyst,IT 培训,实习,找工作,工作实习,Education Agent near me,昆士兰大学,昆士兰科技大学,格里菲斯大学,附近的留学中介,市中心,Brisbane City,Queen Street,Adelaide Street,Edward Street"
        />
      </Helmet>
      <Hero refs={refs} />
      <Main scroll={scroll} refs={refs} />
    </React.Fragment>
  );
}

export default Home;
