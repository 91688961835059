import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "./Testimonials.css";
import testimonialsData from "../data/testimonialsData";

SwiperCore.use([Autoplay, Pagination]);

function Testimonials({ reference }) {
  return (
    <section id="testimonials" className="testimonials" ref={reference}>
      <div className="container position-relative">
        <div
          className="testimonials-slider swiper-container"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Swiper
            speed={600}
            loop={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            slidesPerView={"auto"}
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true,
              bulletClass: "swiper-pagination-bullet",
            }}
          >
            {testimonialsData.map((item) => (
              <SwiperSlide key={item.id}>
                <div className="testimonial-item">
                  <img
                    src={item.imgUrl}
                    className="testimonial-img"
                    alt={item.imgAlt}
                  />
                  <h3>{item.name}</h3>
                  <h4>{item.occupation}</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    {item.comments}
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
