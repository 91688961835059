import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Nav.css";
import universityData from "../data/navData";

// the problem is the window path "/" is lost when go back to the prev page
// find the reason is scroll position get lost when go back page
function Nav({ scroll, refs }) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [dropdownActive, setDropdowActive] = useState(false);
  const [subDropdownActive, setSubDropdowActive] = useState(false);
  const location = useLocation();

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const handleDropdowActive = () => {
    setDropdowActive(!dropdownActive);
  };

  const handleSubDropdowActive = () => {
    setSubDropdowActive(!subDropdownActive);
  };

  // IMPORTANT !!!!!
  // handle the active navigation status, must check if ref is not null first
  const handleActiveNav = (scroll, currentSection, nextSection) => {
    if (refs.hero.current) {
      if (
        scroll > 0 &&
        scroll >=
          refs[currentSection].current.offsetTop -
            refs.header.current.offsetHeight &&
        scroll <
          refs[nextSection].current.offsetTop - refs.header.current.offsetHeight
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return;
    }
  };

  const handlePageScroll = (section) => (e) => {
    e.preventDefault();
    window.scrollTo(
      0,
      refs[section].current.offsetTop - refs.header.current.offsetHeight + 1
    );
  };

  return (
    <nav id="navbar" className={`navbar ${toggleMenu ? "navbar-mobile" : ""}`}>
      <ul>
        <li>
          {location.pathname === "/" ? (
            <Link
              className={`nav-link scrollto ${
                scroll === 0 || handleActiveNav(scroll, "header", "about")
                  ? "active"
                  : undefined
              }`}
              to="/"
              onClick={handlePageScroll("header")}
            >
              <i className="bi bi-house-fill"></i>
            </Link>
          ) : (
            <Link className="nav-link scrollto" to="/">
              返回主页
            </Link>
          )}
        </li>
        {location.pathname === "/" && (
          <>
            <li>
              <Link
                className={`nav-link scrollto ${
                  handleActiveNav(scroll, "about", "counter")
                    ? "active"
                    : undefined
                }`}
                to="/"
                onClick={handlePageScroll("about")}
              >
                关于我们
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link scrollto ${
                  handleActiveNav(scroll, "services", "portfolio")
                    ? "active"
                    : undefined
                }`}
                to="/"
                onClick={handlePageScroll("services")}
              >
                服务项目
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link scrollto ${
                  handleActiveNav(scroll, "portfolio", "team")
                    ? "active"
                    : undefined
                }`}
                to="/"
                onClick={handlePageScroll("portfolio")}
              >
                活动展示
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link scrollto ${
                  handleActiveNav(scroll, "team", "testimonials")
                    ? "active"
                    : undefined
                }`}
                to="/"
                onClick={handlePageScroll("team")}
              >
                核心团队
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link scrollto ${
                  handleActiveNav(scroll, "contact", "footer")
                    ? "active"
                    : undefined
                }`}
                to="/"
                onClick={handlePageScroll("contact")}
              >
                联系我们
              </Link>
            </li>
          </>
        )}
        <li className="dropdown">
          <Link to="/" onClick={handleDropdowActive}>
            <span>更多内容</span> <i className="bi bi-chevron-down"></i>
          </Link>
          <ul className={toggleMenu && dropdownActive ? "dropdown-active" : ""}>
            <li className="dropdown">
              <Link to="/" onClick={handleSubDropdowActive}>
                <span>澳洲八大</span>
                <i className="bi bi-chevron-right"></i>
              </Link>
              <ul
                className={
                  toggleMenu && subDropdownActive ? "dropdown-active" : ""
                }
              >
                {universityData.map((uni) => (
                  <li key={uni.id}>
                    <a target="_blank" rel="noreferrer" href={uni.link}>
                      {uni.name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <a target="_blank" href="mailto:info.austlinkbne@gmail.com">
                求职应聘
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <i
        className={`bi ${toggleMenu ? "bi-x" : "bi-list"} mobile-nav-toggle`}
        onClick={handleToggleMenu}
      ></i>
    </nav>
  );
}

export default Nav;
