import React, { useState, useEffect } from "react";
import "./Contact.css";
import contactData from "../data/contactData";
import ContactForm from "./ContactForm";

function Contact({ reference }) {
  const [contact, setContact] = useState({});

  useEffect(() => {
    setContact(contactData);
  }, []);

  return (
    <section id="contact" className="contact" ref={reference}>
      <div className="container">
        <div className="section-title">
          <span>Contact</span>
          <h2>联系我们</h2>
          <p>{contact.content}</p>
        </div>
      </div>

      <div className="map">
        <iframe
          style={{ border: "0", width: "100%", height: "350px" }}
          src={contact.mapUrl}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="container">
        <div className="info-wrap mt-5">
          <div className="row">
            <div className="col-lg-4 info">
              <i className="ri-map-pin-line"></i>
              <h4>公司地址</h4>
              <p>{contact.address}</p>
              <p>{contact.addressSecondLine}</p>
            </div>

            <div className="col-lg-4 info mt-4 mt-lg-0">
              <i className="ri-mail-line"></i>
              <h4>公司邮箱</h4>
              <p>
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
                <br />
                <a href={`mailto:${contact.secondEmail}`}>
                  {contact.secondEmail}
                </a>
              </p>
            </div>

            <div className="col-lg-4 info mt-4 mt-lg-0">
              <i className="ri-phone-line"></i>
              <h4>联系电话</h4>
              <p>
                <a href={`tel:${contactData.tel}`}>{contact.tel}</a>
                <br />
                <a href={`tel:${contactData.phone}`}>{contact.phone}</a>
              </p>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
    </section>
  );
}

export default Contact;
