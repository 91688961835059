import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/Breadcrumbs";
import "./notFound.css";

function NotFound() {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("/");
    }, 5000);
  }, []);

  return (
    <>
      <Helmet>
        <title>【澳信教育 BNE】404该页面不存在</title>
        <meta name="description" content="澳信教育 布里斯班 404 该页面不存在" />
      </Helmet>
      <main id="main">
        <Breadcrumbs title="404 该页面不存在" page="Not Found" />
        <section className="inner-page">
          <div className="container">
            <div className="raw text-center not-found">
              <h1>404</h1>
              <h3>抱歉，该访问页面不存在...</h3>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default NotFound;
