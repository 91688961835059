import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "remixicon/fonts/remixicon.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import PortfolioDetails from "./components/PortfolioDetails";
import Footer from "./components/Footer";
import BackToTop from "./components/BackToTop";
import PreLoader from "./components/Preloader";
import NotFound from "./pages/NotFound";

function App() {
  const [scroll, setScroll] = useState(0);
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const preloaderRef = useRef(null);
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const counterRef = useRef(null);
  const servicesRef = useRef(null);
  const portfolioRef = useRef(null);
  const contactRef = useRef(null);
  const teamRef = useRef(null);
  const testimonialsRef = useRef(null);

  const sectionRefs = {
    header: headerRef,
    footer: footerRef,
    preLoader: preloaderRef,
    hero: heroRef,
    about: aboutRef,
    counter: counterRef,
    services: servicesRef,
    portfolio: portfolioRef,
    contact: contactRef,
    team: teamRef,
    testimonials: testimonialsRef,
  };

  useEffect(() => {
    //   // remove the issue of SameSite cookies
    //   // document.cookie = "promo_shown=1; SameSite=None; Secure";
    //   // remove preloader once all loaded
    // document.addEventListener("DOMContentLoaded", () => {
    //   setTimeout(function () {
    //     preloaderRef.current.remove();
    //   }, 500);
    // });

    // window.addEventListener("load", () => {
    //   // set up mannually 0.5s for preloading
    setTimeout(function () {
      preloaderRef.current.remove();
    }, 686);
    //   preloaderRef.current.remove();
    // });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setScroll(window.scrollY);
      });
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <React.Fragment>
      <PreLoader reference={preloaderRef} />
      <Header scroll={scroll} refs={sectionRefs} />
      <Routes>
        <Route
          exact
          path="/"
          element={<Home scroll={scroll} refs={sectionRefs} />}
        />
        <Route
          exact
          path="project-details/:id"
          element={<PortfolioDetails refs={sectionRefs} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer refs={sectionRefs} />
      <BackToTop scroll={scroll} />
    </React.Fragment>
  );
}

export default App;
