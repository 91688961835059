import React from "react";
import "./Main.css";
import About from "./About";
import AboutList from "./AboutList";
import Counter from "./Counter";
import Services from "./Services";
import Portfolio from "./Portfolio";
import Team from "./Team";
import Testimonials from "./Testimonials";
import Contact from "./Contact";

function Main({ scroll, refs }) {
  return (
    <main id="main">
      <About reference={refs.about} />
      <AboutList />
      <Counter scroll={scroll} refs={refs} />
      <Services reference={refs.services} />
      <Portfolio reference={refs.portfolio} />
      <Team reference={refs.team} />
      <Testimonials reference={refs.testimonials} />
      <Contact reference={refs.contact} />
    </main>
  );
}

export default Main;
