import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumbs.css";

function Breadcrumbs({ title, page }) {
  return (
    <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{title}</h2>
          <ol>
            <li>
              <Link to="/">
                <i className="bi bi-house-fill"></i>
              </Link>
            </li>
            <li>{page}</li>
          </ol>
        </div>
      </div>
    </section>
  );
}

export default Breadcrumbs;
