import React, { useEffect, useState } from "react";
import "./AboutList.css";
import { aboutData } from "../data/aboutData";

function AboutList() {
  const [aboutList, setAboutList] = useState([]);
  useEffect(() => {
    setAboutList(aboutData);
  }, []);

  return (
    <section id="about-list" className="about-list">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1">
            {aboutList.map((item) => (
              <div
                key={item.id}
                className={
                  item.id === 1 ? "icon-box mt-5 mt-lg-0" : "icon-box mt-5 "
                }
              >
                <i className={item.icon}></i>
                <h4>{item.title}</h4>
                <p>{item.content}</p>
              </div>
            ))}
          </div>
          <div className="image col-lg-6 order-1 order-lg-2"></div>
        </div>
      </div>
    </section>
  );
}

export default AboutList;
